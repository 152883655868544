
import { ComponentPublicInstance, computed, defineComponent, nextTick, onMounted, reactive, ref } from 'vue';
import UITextInput from '@/components/UI/UITextInput.vue';
import UITextDropdown from '@/components/UI/UITextSelect.vue';
import { calidadProductosState, especiesState, factorCortezaState, grupoProductosState } from '@/store/mantenedores.store';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { showToast } from '@/hooks/useToast';
import { useRouter, useRoute } from 'vue-router';
import { validateForm } from '@/utils';

export default defineComponent({
  name: 'ProductCreate',
  components: {
    'ui-text-input': UITextInput,
    'ui-text-select': UITextDropdown
  },
  setup() {
    const codigoRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const codigoAdicionalRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const codigoSAPRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const codigoSISCOPRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const calidadRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const especieRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const factorRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const grupoRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const estadoRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const largoRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const largoFinalRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const largoInicialRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const largoNominalRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const largoVariableRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);

    const isValidForm = () => {
      const formRefs = [
        codigoRef,
        codigoAdicionalRef,
        codigoSAPRef,
        codigoSISCOPRef,
        calidadRef,
        especieRef,
        factorRef,
        grupoRef,
        estadoRef,
        largoRef,
        largoFinalRef,
        largoInicialRef,
        largoNominalRef,
        largoVariableRef
      ];
      let isValid =  validateForm(formRefs);
      function v(value: any[]) {
        const notRequired = ['codigoSAP'];
        if (!notRequired.includes(value[0])) {
          if (value[1] === null || value[1] === undefined || value[1] === '') isValid = false;
        }
      }
      Object.entries({ ...producto}).forEach(el => v(el));
      return isValid;
    };
    const router = useRouter();
    const route = useRoute();
    const producto = reactive({
      codigo: '',
      calidad: '',
      estado: '',
      codigoAdicional: '',
      codigoSAP: '',
      codigoSISCOP: '',
      largo: 0,
      largoFinal: 0,
      largoInicial: 0,
      largoNominal: 0,
      largoVariable: 0,
      grupo: '',
      factorCorteza: '',
      especie: ''
    });
    const calidadDefault = ref('');
    const estadoDefault = ref('');
    const grupoDefault = ref('');
    const factorDefault = ref('');
    const especieDefault = ref('');
    
    const calidadProductos = computed(() =>
      calidadProductosState.value.map((el: any) => ({
        label: el.descripcion,
        id: el._id
      }))
    );
    const especies = computed(() =>
      especiesState.value.map((el: any) => ({
        label: el.descripcion,
        id: el._id
      }))
    );
    const factorCorteza = computed(() =>
      factorCortezaState.value.map((el: any) => ({
        label: el.descripcion,
        id: el._id
      }))
    );
    const grupoProducto = computed(() =>
      grupoProductosState.value.map((el: any) => ({
        label: el.descripcion,
        id: el._id
      }))
    );
    const estadoProductos = [
      { label: 'HABILITADO', id: 'HABILITADO' },
      { label: 'DESHABILITADO', id: 'DESHABILITADO' }
    ];

    const isLoading = ref(false);
    const errors = ref<string[]>([]);
    const saveProduct = async () => {
      const isValid = isValidForm();
      if (!isValid) return;
      try {
        isLoading.value = true;
        errors.value = [];
        const request = await makeRequest<any>(METHODS.PATCH, `producto/${route.params.id}`, true, {
          ...producto
        });

        if (request.ok) {
          showToast('SUCCESS', 'Producto editado exitosamente');
          router.push({ name: 'product.list' });
        } else {
          showToast('ERROR', 'Error en proceso de creación');
          request.errors && (errors.value = request.errors.split(','));
        }
      } catch (error) {
        console.log(error);
        
      } finally {
        isLoading.value = false;
      }
    };
    const getSingleProduct = async () => {
      try {
        if (!route.params.id) {
          return;
        }
        const request = await makeRequest<any>(METHODS.GET, `producto/${route.params.id}`);
        if (request.ok) {
          await nextTick();
          setTimeout(() => {
            producto.codigo = request.payload.codigo;
            producto.calidad = request.payload.calidad;
            producto.estado = request.payload.estado;
            producto.codigoAdicional = request.payload.codigoAdicional;
            producto.codigoSAP = request.payload.codigoSAP;
            producto.codigoSISCOP = request.payload.codigoSISCOP;
            producto.largo = request.payload.largo;
            producto.largoFinal = request.payload.largoFinal;
            producto.largoInicial = request.payload.largoInicial;
            producto.largoNominal = request.payload.largoNominal;
            producto.largoVariable = request.payload.largoVariable;
            producto.grupo = request.payload.grupo;
            producto.factorCorteza = request.payload.factorCorteza;
            producto.especie = request.payload.especie;
            calidadDefault.value = request.payload.calidad;
            estadoDefault.value = request.payload.estado;
            grupoDefault.value = request.payload.grupo;
            factorDefault.value = request.payload.factorCorteza;
            especieDefault.value = request.payload.especie;
            console.log({ producto });
          }, 300);
        }
      } catch (error) {
        console.log(error);
      } 
    };


    onMounted(async () => {
      console.log(route.params);
      await nextTick();
      getSingleProduct();
    });

    return {
      producto,
      calidadProductos,
      estadoProductos,
      especies,
      factorCorteza,
      grupoProducto,
      saveProduct,
      isLoading,
      errors,
      calidadDefault,
      estadoDefault,
      grupoDefault,
      factorDefault,
      especieDefault,
      codigoRef,
      codigoAdicionalRef,
      codigoSAPRef,
      codigoSISCOPRef,
      calidadRef,
      especieRef,
      factorRef,
      grupoRef,
      estadoRef,
      largoRef,
      largoFinalRef,
      largoInicialRef,
      largoNominalRef,
      largoVariableRef
    };
  }
});
